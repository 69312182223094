.tracker-table {
  .task-status-div {
    margin-top: 30px;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 0px 16px !important;
    }
  }
  .ant-table-tbody {
    background: var(--white-color);
    .ant-table-cell {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      padding: 16px;
      .last-update-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
        .date {
          color: var(--subtitle-color);
          font-size: 12px;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
      }
      button {
        background-color: transparent;
        height: 40px;
      }
    }
  }
}
