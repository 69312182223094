.ant-modal-content {
  padding: 36px 40px !important;
}

.edit-confirmation-icon {
  background: var(--PRIMARY-GREEN-100);
}

.delete-conformation-modal {
  .ant-modal-content {
    padding: 24px !important;
    .ant-modal-body {
      .model-container {
        display: flex;
        justify-content: start;
        align-items: flex-start;
        gap: 16px;
        padding: 0px;
        .model-content {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .model-title {
            color: var(--secondary-color, #22282f);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
          }
          .model-subtile {
            color: var(--secondary-color, #22282f);
            font-size: 14px;
            line-height: 150%;
          }
        }
      }
    }
    .ant-modal-footer {
      margin-top: 32px;
      .modal-action-buttonGroup {
        gap: 12px;
        button {
          height: 40px;
        }
      }
    }
  }
  .modal-action-buttonGroup {
    display: flex;
    justify-content: end;
    gap: 24px;
    align-items: center;
    width: 100%;
  }
}

.edit-conformation-modal {
  .ant-modal-content {
    padding: 36px 40px;
    .ant-modal-body {
      .model-container {
        display: flex;
        justify-content: start;
        align-items: flex-start;
        gap: 16px;
        padding: 0px;
        .model-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .model-title {
            color: var(--secondary-color, #22282f);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
          }
          .model-subtile {
            color: var(--secondary-color, #22282f);
            font-size: 16px;
          }
        }
      }
    }
    .ant-modal-footer {
      margin-top: 32px;
    }
  }
  .modal-action-buttonGroup {
    display: flex;
    justify-content: end;
    gap: 24px;
    align-items: center;
    width: 100%;
  }
  .model-icon {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #fcecec;
  }
}

.model-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.input-box {
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.add-kmp-modal {
  .model-heading {
    color: var(--secondary-color, #22282f);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    width: 100%;
  }
  .modal-actiongroup {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}

.label-text {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-size: 14px;
  color: var(----font-darkgrey-color);
  padding: 5px 0;
}

.error {
  color: var(--button-red);
  font-size: 12px;
  font-style: normal;
}

.error-modal-wrapper {
  display: flex;
  gap: 24px;

  .img {
    width: 70px;
  }

  .model-status-title {
    font-size: 20px;
    font-weight: 700;
  }
  .model-status-icon {
    border-radius: var(--Dimensions-Corner-Radius-xl, 8px);
    // background: var(--Colors-ERROR-50);
    img {
      padding: 0 12px;
    }
    height: 64px;
    width: 64px;
  }
  .model-status-desc {
    font-size: 16px;
  }
  .modal-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.error-modal {
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}
.coming-soon-wrapper {
  width: 50%;
  .coming-soon-model {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 2.2rem;
      color: rgb(51, 51, 51);
    }
    p {
      margin-top: 1rem;
      font-size: 0.9rem;
      color: rgb(102, 102, 102);
    }
  }
}

// Logs View Modal Start

.logs-view-wrapper {
  border-radius: var(--Dimensions-Corner-Radius-xl, 8px);
  border: 0.5px solid var(--Colors-Primary-Grayscale_Border-Border-default, #babcbf);
  background: var(--Colors-CREAM-100, #fbfbfa);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  .logs-view-model {
    .logs-view-title {
      color: var(--gray-500-base, #22282f);
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 24px;
    }

    .logs-view-details {
      display: flex;
      align-items: flex-start;
      gap: var(--Dimensions-Spacing-lg, 16px);
      align-self: stretch;
      margin-bottom: 4px;
      .logs-view-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--Dimensions-Spacing-2xs, 4px);
        .logs-user-letter {
          display: flex;
          width: var(--Dimensions-Spacing-3xl, 32px);
          height: var(--Dimensions-Spacing-3xl, 32px);
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }
        .logs-line {
          width: 1px;
          height: 40px;
        }
      }
      .logs-user-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--Dimensions-Spacing-2xs, 4px);
        h1 {
          color: var(--gray-500-base, #22282f);
          font-size: 14px;
          font-weight: 600;
          line-height: 150%; /* 21px */
          margin-bottom: 0px !important;
        }
        span {
          color: var(--GRAY-300, #6b6f74);
          font-size: 14px;
          font-weight: 500;
        }
        p {
          color: var(--GRAY-300, #6b6f74);
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}
