.modal-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  img {
    height: 56px;
    width: 56px;
    padding: 14px;
    border-radius: 8px;
    background: var(--white-color);
  }
  .modal-typography {
    display: flex;
    flex-direction: column;
    .primary-text {
      font-size: 20px;
      font-weight: 700;
      line-height: 150%;
      color: var(--secondary-color);
    }
    .secondary-text {
      font-size: 14px;
      line-height: 150%;
      color: var(--font-darkgrey-color);
    }
  }
}
.modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 24px;
  width: 100%;
  button {
    width: 100%;
  }
}
.export-modal {
  .ant-modal-content {
    padding: 36px;
  }
  .ant-modal-close {
    background: var(--close-icon-background);
    border-radius: 29px;
  }
}
