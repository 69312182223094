.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .editable-cell-value-wrap {
    white-space: pre-wrap;
    padding: 5px 8px;
  }
  .ant-table-column-sort {
    background: unset !important;
  }
  .ant-table-pagination {
    padding-right: 0;
    margin: 12px 0px !important;
  }
  .table-autocomplete {
    .autocomplete-input {
      border: none;
      box-shadow: none;
    }
  }
  .editable-row:hover,
  .editable-cell-value-wrap:hover {
    border: 1px solid var(--inputs-border-color);
    background-color: var(--PLAIN-WHITE);
    border-radius: 4px;
    padding: 4px 7px;
    min-height: 30px;
  }
  .ant-input-number-input {
    padding: 0;
  }
  .editable-textarea {
    border: 0.5px solid var(--font-darkgrey-color);
    border-radius: 4px;
    padding: 4px 7px;
    height: 100%;
    .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:focus-visible {
      border: none !important;
    }
    &:focus {
      box-shadow: var(--input-box-shadow);
    }
  }
  .secondary-table-title {
    color: var(--subtitle-color);
    font-feature-settings:
      "case" on,
      "cpsp" on;
  }
  .table {
    .ant-table-cell-row-hover {
      background-color: var(--table-row-hover-color) !important;
    }
    tbody {
      background-color: var(--input-bg-color);
    }
    .ant-table-column-sort {
      background: var(--input-bg-color);
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid var(--inputs-border-color);
    padding: 16px 24px;
  }
  .ant-table-cell-scrollbar {
    background: var(--box-table-border-color) !important;
    border-bottom: 0.5px solid var(--inputs-border-color) !important;
  }
  .table-with-header {
    tbody {
      vertical-align: top;
    }
    .ant-table-tbody > tr > td {
      border-inline-end: 0.5px solid var(--inputs-border-color) !important;
    }
    .ant-table-thead > tr > th {
      border-inline-end: 0.5px solid var(--inputs-border-color) !important;
    }
    .ant-table-container {
      border: 0.5px solid var(--input-border-color);
      border-radius: 0;
      border-inline-start: none !important;
      border-top: none !important;
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
    }
    .ant-table {
      border-radius: 0;
    }
    table > thead > tr:first-child > *:first-child {
      border-start-start-radius: 0 !important;
    }
    table > thead > tr:first-child > *:last-child {
      border-start-end-radius: 0 !important;
    }
    .ant-table-thead > tr > .ant-table-cell {
      font-size: 12px;
      font-weight: 600;
      color: var(--font-body-color);
    }
  }
  .ant-table-thead > tr > th {
    background-color: var(--box-table-border-color) !important;
    border-bottom: 0.5px solid var(--inputs-border-color);
  }
  .table-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0px 0px;
    border-top: 0.5px solid var(--inputs-border-color);
    border-right: 0.5px solid var(--inputs-border-color);
    border-left: 0.5px solid var(--inputs-border-color);
    background: var(--white-color);
    padding: 8px;
    .right-icon-div {
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        padding: 4px;
        cursor: pointer;
      }
      .filter-icon {
        position: relative;
      }
      .sort {
        padding: 8px;
        margin-left: 16px;
        background: rgba(219, 219, 217, 0.4);
        width: 32px;
        height: 32px;
        border-radius: 6px;
      }
      .active {
        background: var(--title-color);
      }
      .ellipse {
        position: absolute;
        right: -3px;
        top: -2px;
        padding: 0;
      }
    }
    .left-search-div {
      width: 280px;
      .ant-input-prefix {
        margin-inline-end: 10px;
      }
      .search-icon {
        cursor: pointer;
      }
    }
  }
  .ant-table {
    border: 0.5px solid var(--inputs-border-color);
    border-radius: 4px;
    border-bottom: none;
  }
  .ant-table-body {
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto !important;
    max-height: 25vh;
  }
  .ant-table-thead > tr > .ant-table-cell {
    font-size: 12px;
    font-weight: 600;
    color: var(--font-body-color);
    padding: 0px 24px;
    border-start-start-radius: 0 !important;
    border-start-end-radius: 0 !important;
    white-space: nowrap;
  }
  .ant-table-thead,
  .ant-table-thead > tr {
    height: 40px;
  }
  .ant-table-cell {
    font-size: 16px;
    color: var(--font-body-color);
    white-space: pre-wrap;
    font-family: "Plus Jakarta Sans";
  }
  .ant-table-header {
    height: 40px;
    border-radius: 0px !important;
  }
  .wrapper-label {
    display: flex;
    margin-bottom: 25px;

    img {
      margin-right: 20px;
    }
  }
  .label {
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-color);
  }
  .input-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    .currency-symbol {
      margin-bottom: 3px;
      color: var(--black-color);
      position: relative;
      left: 14px;
      top: 1px;
    }

    .textinput {
      border: 0.5px solid var(--input-border-color);
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      color: var(--secondary-color);
      background-color: var(--white-color);
      /* margin-left: 1px; */
    }

    input:focus {
      outline: none;
      background-color: var(--white-color);
      color: var(--black-color);
    }
  }
  .search-wrapper {
    display: flex;
    justify-content: end;
    border-top: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    border-right: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    border-left: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    background: rgb(241, 241, 239);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .table-search {
      width: 250px;
      height: 30px;
      box-shadow: none;
      border-radius: var(--Dimensions-Corner-Radius-md, 4px);
      border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
      background: var(--Colors-CREAM-200, #f9f9f8);
      margin: 10px;
    }
  }
}

.header-bar-tooltip {
  .ant-tooltip-inner {
    color: var(--font-body-color);
    font-size: 14px;
    font-weight: 500;
  }
}

.filter-drawer {
  z-index: 10000000000;
  .date-range {
    padding: 36px 16px;
  }
  .hours-range {
    display: flex;
    gap: 16px;
    padding: 36px 16px;
    .hours-label-div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-start;
      width: 100%;
      .ant-input-number-outlined {
        width: 100%;
      }
    }
  }
  .ant-drawer-content-wrapper {
    width: 420px !important;
  }
  .ant-drawer-header {
    padding: 0;
    border: none;
  }
  .header,
  .footer {
    display: flex;
    padding: 14px 16px 15px 16px;
    align-items: center;
    align-self: stretch;
    background: var(--footer-background);
  }
  .header {
    border-bottom: 0.5px solid var(--title-color);
    justify-content: space-between;
    .text-wrapper {
      color: var(--black-color);
      font-size: 18px;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }
    .text-wrapper-reset {
      color: var(--main-secondary-color);
      font-size: 14px;
      font-weight: 500;
      line-height: 150%; /* 21px */
      cursor: pointer;
    }
  }
  .search-wrapper {
    background: var(--search-background);
    display: flex;
    padding: 11px 16px 12px 16px;
    align-items: center;
    align-self: stretch;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  .ant-drawer-body {
    padding: 0;
  }
  .footer {
    gap: 16px;
    border-top: 0.5px solid var(--inputs-border-color);
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
  }
  .ant-drawer-footer {
    padding: 0;
    border-top: none;
  }
  .filter-drawer-container {
    .ant-input-prefix {
      margin-inline-end: 12px;
    }
    .filter-collapse {
      border: none !important;
      .ant-collapse-header {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        /* border-bottom: 0.5px solid var(--inputs-border-color); */
        background: var(--search-background);
        .ant-collapse-header-text {
          color: var(--font-body-color);
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
          display: flex;
          gap: 16px;
          align-items: center;
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
      .ant-collapse-content {
        border: none;
        border-top: 0.5px solid var(--inputs-border-color);
        background: var(--button-disabled-green);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06) inset;
        max-height: 200px;
        overflow: auto;
        .ant-collapse-content-box {
          padding: 0;
          .ant-checkbox-group-item {
            display: flex;
            padding: 14px 16px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            font-size: 14px;
            color: var(--font-body-color);
            width: 100%;
            &:hover {
              background: rgba(227, 227, 227, 0.4);
              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #00a97d;
                border: none !important;
              }

              .ant-checkbox .ant-checkbox-inner {
                border: 1.5px solid var(--checkbox-border);
              }
            }
          }
          .ant-checkbox-wrapper-checked {
            background: rgba(227, 227, 227, 0.4);
          }
        }
      }
      .ant-collapse-item {
        /* border-bottom: 0.5px solid var(--inputs-border-color); */
        border-bottom: none;
        border-top: 0.5px solid var(--inputs-border-color);
        border-left: none;
        border-right: none;
      }
      .ant-collapse-item:last-child {
        border-bottom: 0.5px solid var(--inputs-border-color);
        border-radius: unset;
      }
    }
  }
}

.empty-table {
  height: 200px;
}
