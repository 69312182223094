.task-status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 80px;
  .task-status-div {
    border-radius: var(--border-global);
    .header {
      display: flex;
      padding: 10px var(--Dimensions-Spacing-lg, 16px);
      align-items: center;
      justify-content: space-between;
      border-bottom: var(--border-global);
      // background: var(--box-table-border-color);
      background: transparent;
      border-radius: var(--border-global) var(--border-global) 0 0;
      span {
        color: var(--font-body-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 150%; /* 18px */
      }
    }
    .task-div {
      display: flex;
      padding: var(--Dimensions-Spacing-lg, 16px);
      align-items: center;
      background: transparent;
      justify-content: space-between;
      cursor: pointer;
      // &:hover {
      //   background: var(--table-row-hover-color);
      // }
    }
    .task-div:last-of-type {
      border-bottom-left-radius: var(--border-global);
      border-bottom-right-radius: var(--border-global);
    }
  }

  .title-component {
    display: flex;
    justify-content: space-between;
    .title {
      color: var(--white-color);
      font-size: 20px;
      font-weight: 700;
      line-height: 150%; /* 30px */
    }
    .overall-status-wrapper {
      display: flex;
      gap: 12px;
      align-items: center;
      color: var(--title-color);
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .table-header-skeleton {
    display: flex;
    justify-content: space-between;
    background-color: var(--white-color);
  }
}
