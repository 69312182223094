.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-panel,
  .right-panel {
    display: flex;
    gap: 16px;
  }
  .left-panel {
    img {
      width: 24px;
    }
  }
  .right-panel {
  }

  .center-panel {
    .finalize-box {
      display: flex;
      padding: 5px 16px;
      justify-content: start;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border-top: 0.5px solid var(--inputs-border-color, #babcbf);
      background: var(--title-color, #dbdbd9);
      position: sticky;
      bottom: 80px;
      .finalize-input {
        width: 12vw;
        height: 42px;
      }

      .finalize-label {
        color: var(--black-color);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
