.breadcrumb-wrapper {
  text-align: left;
  .link {
    text-decoration: none;
  }
  .breadcrumb-main {
    color: var(--main-secondary-color);
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
  }
  .breadcrumb-right-arrow {
    margin: 0px 10px;
  }
  .breadcrumb-sub {
    color: var(--title-color);
    font-weight: 600;
    font-size: 12px;
  }

  .title {
    color: var(--title-color);
    font-size: 32px;
    font-weight: 700;
    margin: 35px 0px 24px;
  }
}
