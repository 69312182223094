.welcome-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .date-pickers-year-end {
    display: flex;
    width: 100%;
    gap: 16px;
    .financial-year {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 32px;
      gap: 12px;
      align-items: flex-start;
      font-size: 14px;
      font-weight: 700;
      line-height: 150%; /* 21px */
      .ant-select-single {
        height: 48px;
        .ant-select-selector {
          border-radius: 4px;
          background: var(--drawer-bg);
          border: 0.5px solid var(--inputs-border-color);
        }
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          text-align: start;
        }
        .ant-select-selection-placeholder {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .ant-picker {
        height: 48px;
        width: 100%;
        cursor: pointer;
        .ant-picker-input {
          flex-direction: row-reverse;
          gap: 8px;
        }
        .ant-picker-input > input {
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
  .wrapper-label {
    display: flex;

    img {
      margin-right: 24px;
    }
  }
  .label {
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-color);
    text-align: left;
  }
}
