.file-upload-wrapper {
  background-color: var(--input-border-color);
  width: 100%;
  .wrapper-label {
    display: flex;
    margin-bottom: 25px;

    img {
      margin-right: 20px;
    }
  }
  .label {
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-color);
  }
  .disbale-uploader {
    opacity: 0.4;
    pointer-events: none;
  }

  .file-upload-box {
    border: 2px dotted #b6cfdf;
    border-radius: 4px;
    padding: 40px;
    height: 300px;
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--white-color);

    .info {
      color: var(--font-body-color);
      font-size: 16px;
      margin-top: 15px;
      .link {
        font-weight: 600;
        color: var(--PRIMARY-GREEN-600);
        cursor: pointer;
        -webkit-transition-duration: 0.4s;
        /* Safari */
        transition-duration: 0.4s;
      }
      .link:hover {
        text-decoration: underline;
      }
    }

    .accepted-files {
      color: var(--subtitle-color);
      font-size: 14px;
      margin-top: 8px;
    }

    .uploadfile-btn {
      background-color: #e9eaea;
      width: 170px;
      height: 50px;
      padding: 12px 24px 12px 24px;
      border-radius: 6px;
      font-size: 16px;
      border: 1px solid var(--input-border-color);
      color: #a4a8aa;
      margin-top: 20px;
    }

    .active {
      background-color: var(--main-color);
      color: var(--black-color);
      border: 1px solid var(--main-color);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .files-selected {
      background-color: #eff5f8;
      padding: 6px 16px 6px 16px;
      color: #5a7185;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 18.5px;
      height: 40px;
      width: 500px;

      .cross-icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .error {
      color: red;
      margin: 10px;
    }
    .success {
      color: green;
      margin: 10px;
    }
  }
}
.file-upload-container {
  width: 100%;
}
