.decoder-Container {
  height: 100%;
  padding: 24px 0px 100px 0px;
  margin: var(--top-header-width) 0px 0px var(--left-menu-width);
  position: relative;
  overflow: auto;
  .card {
    /* padding: 32px; */
    margin: 0 70px;
    border-radius: 16px;
    background: var(--Color_Theme-Card-Background, #dbdbd9);
    position: static;
  }
  .decoder-sheet {
    .e-sheet-panel {
      height: 60vh !important;
    }
    .loading-skeleton {
      height: 60vh !important;
    }
    .e-spreadsheet {
      height: 60vh !important;
    }
  }
  .multi-options-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
    border: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    background: var(--lightcream-500-base, #f1f1ef);
    margin: 16px 0;
    .ant-radio-wrapper {
      width: 100%;
      padding: 15px;
      border-bottom: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    }
  }
  .multi-options-box-loading {
    padding: 15px;
    border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
    border: 0.5px solid var(--Color_Theme-Table_Border, #babcbf);
    background: var(--lightcream-500-base, #f1f1ef);
    width: 100%;
  }
  .checked-error {
    color: var(--Color_Theme-Error, #f44336);
    font-size: 12px;
    margin-left: 5px;
    text-align: left;
  }
  .wrapper-label-decoder {
    margin: 25px 25px 10px 25px !important;
    text-align: left;
    display: flex;
    gap: 15px;
    .label {
      color: var(--white-color) !important;
      font-size: 20px;
      line-height: 30px;
    }
  }
  .entity-name {
    color: var(--white-color);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding: 0 24px;
    margin-top: 10px;
    text-align: left;
  }
  .decoder-display-container {
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 15px 25px 0 25px;
    .sheet-left-pannel {
      width: 67%;
    }
    .sheet-right-pannel {
      width: 33%;
      height: 60vh;
      overflow: auto;
      padding: 16px;
      border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
      background: var(--lightcream-500-base, #f1f1ef);
      .active-feild {
        box-shadow:
          0 -4px 3px -3px #00a97d,
          0 4px 3px -3px #00a97d;
        border: 1.5px solid #00a97d !important;
      }
      .decoder-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .decoder-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          // gap: 10px;

          .input-heading {
            color: var(--gray-500-base, #22282f);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
            text-align: left;
          }
          .input-label {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            padding: 6px 0;
            width: 45%;
            text-align: left;
          }

          .decoder-select-wrap {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 5px;
            margin-left: 10px;
            .decoder-select {
              width: 100%;
              height: 35px;
              text-align: left;
              /* gap: var(--Dimensions-Spacing-sm, 8px); */
              /* align-self: stretch; */
            }
            .error-message {
              color: var(--red-500-base, #f44336);
              font-size: 12px;
            }
          }
          .input-date {
            width: 45% !important;
            flex-grow: 1;
            height: 35px;
            display: flex;
            // justify-content: end !important;
            justify-content: end !important;
          }
          .input-value,
          .input-date {
            width: 50%;
            color: var(--Colors-GRAY-500, #22282f);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            display: flex;
            padding: var(--Dimensions-Spacing-xs, 4px)
              var(--Dimensions-Spacing-md, 12px);
            align-items: center;
            gap: var(--Dimensions-Spacing-sm, 8px);
            align-self: stretch;
            border-radius: var(--Dimensions-Corner-Radius-md, 4px);
            border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
            background: var(--Colors-CREAM-200, #f9f9f8);
            justify-content: space-between;
            .date-placeholder {
              color: #babcbf;
              font-size: 12px;
            }
          }
        }
        .multiple-error {
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-top: -17px;
        }
        .error-message {
          color: var(--red-500-base, #f44336);
          font-size: 12px;
        }
      }
      .custom-decoder-sheet-btn-wrap {
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
        // padding: 0 10px;
        .custom-decoder-sheet-btn {
          width: 60%;
          height: 35px;
          text-align: right;
        }
      }
    }
  }
}
.decoder-track-table-wrapper {
  padding: var(--Dimensions-Spacing-md, 12px)
    var(--Dimensions-Spacing-2xl, 24px) var(--Dimensions-Spacing-lg, 16px)
    var(--Dimensions-Spacing-2xl, 24px);

  .decoder-track-heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 16px;
    h2 {
      color: var(--lightcream-500-base, #f1f1ef);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
    }
    .button-group-wrapper {
      display: flex;
      align-items: flex-start;
      gap: var(--Dimensions-Spacing-md, 12px);
    }
  }
  .decoder-track-table {
    margin-bottom: 36px;
    .table-container {
      border-radius: 6px;
      overflow: hidden;
      .ant-table-tbody {
        .ant-table-cell {
          color: var(--gray-500-base, #22282f);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
}
.decoder-sheet-container-display {
  background-color: var(--input-border-color);
  width: 100%;
  .wrapper-label {
    display: flex;
    margin-bottom: 25px;

    img {
      margin-right: 20px;
    }
  }
  .label {
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-color);
  }
}
.decoder-track-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 30px 20px 20px;
  border-bottom: 0.5px solid #32373d;
  background: linear-gradient(180deg, #22282f 0%, #1e242a 100%);
  .decoder-track-header-title {
    color: #dbdbd9;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }
  .decoder-engament-text {
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--LIGHT_CREAM-600, #dbdbd9);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    line-height: 150%; /* 21px */
    span {
      color: #e9eaea;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin: 0 5px;
    }
  }
}
